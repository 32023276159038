import * as sessionReplay from '@amplitude/session-replay-browser'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import TrackingEvent from './TrackingEvent'
import { getAppGlobals } from '~/global-data'

interface IdentifyTraits {
  [key: string]: undefined
}

export type WindowAnalytics = Window & { [key: string]: object[] }
declare const window: WindowAnalytics

const rudderAnalytics = new RudderAnalytics()

const defaults = {
  appName: 'remix-patient-portal',
}

export const load = (
  analyticsWriteKey: string,
  analyticsDataPlaneUrl: string,
): Promise<void> => {
  if (!analyticsWriteKey || !analyticsDataPlaneUrl) {
    return Promise.reject(
      new Error('Rudderstack writeKey or dataPlaneUrl is missing.'),
    )
  }

  return new Promise((resolve) => {
    rudderAnalytics.load(analyticsWriteKey, analyticsDataPlaneUrl, {})
    rudderAnalytics.ready(() => {
      setupAmplitudeSessionReplay()
      resolve()
    })
  })
}

/**
 * Returns the current session ID.
 */
export const getSessionId = () => rudderAnalytics.getSessionId()

/*
 * Page event with Rudderstack. This is done automatically in all
 * pages by exporting a handle with the page name like so:
 *
 * ```typescript
 * export const handle = {
 *   pageName: 'Appointments',
 * }
 * ```
 */
export const page = (pageName: string) => {
  rudderAnalytics.page(pageName, {
    ...sessionReplay.getSessionReplayProperties(),
    __platform: defaults.appName,
  })
}

export const identify = (userId: string, traits: IdentifyTraits = {}) => {
  rudderAnalytics.identify(userId, traits)
}

/*
 * Track an event with Rudderstack by passing a subclass of the
 * Event class.
 */
export const track = (event: TrackingEvent) => {
  rudderAnalytics.track(event.name, {
    ...event.properties(),
    ...sessionReplay.getSessionReplayProperties(),
    __platform: defaults.appName,
  })
}

export const trackGTM = (eventName: string) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(['event', eventName])
  }
}

/*
 * Clears the ID, anonymous ID, and traits of both the user and the group.
 */
export const reset = () => {
  rudderAnalytics.reset(true)
}

const setupAmplitudeSessionReplay = () => {
  const { APP_ENV, AMPLITUDE_API_KEY } = getAppGlobals()
  const isProd = APP_ENV === 'production'
  if (AMPLITUDE_API_KEY) {
    const sessionId = rudderAnalytics.getSessionId()
    sessionReplay.init(AMPLITUDE_API_KEY, {
      deviceId: rudderAnalytics.getAnonymousId(),
      sessionId: sessionId ? sessionId : undefined,
      sampleRate: isProd ? 0.1 : 0,
      privacyConfig: {
        maskSelector: ['input', '[data-masked]'],
        blockSelector: ['[data-blocked]'],
      },
    })
  }
}
