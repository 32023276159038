/**
 * It provides a consistent interface for defining tracking events.
 * This class should be subclassed to define specific tracking events.
 *
 * ```typescript
 * class MyEvent extends TrackingEvent {
 *   constructor() {
 *     super('my_event')
 *   }
 *
 *   properties() {
 *     return {
 *       key: 'value',
 *     }
 *   }
 * }
 * ```
 */
export default abstract class TrackingEvent {
  protected constructor(public name: string) {
    this.name = name
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public properties(): Record<string, any> {
    return {}
  }
}
